import React from 'react';
import Layout from "../../components/layout";
import PdtDesc from "../../components/PdtDescription";
import { graphql } from 'gatsby';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const constructionCare = ({ data }) => (
  <Layout>
    <Row>
      <Col><h1>Construction Care Chemicals</h1>
      </Col>

    </Row>
    {/* metallic emulsions */}
    <PdtDesc
      image={data.metallicEmulsion.childImageSharp.fluid}
      heading={"Tile Coat/Mettalic Emulsion"}
      data={"With vast experience and knowledge, we are involved in offering an extensive range of Tile Grout. It is prepared for free flow grouting of gap. This is processed under the strict guidance of highly skilled professionals using advanced techniques to meet the client’s requirements. We also assure safe and timely delivery of our products. Features are High elasticity, Long service & Easy to use."}
    ></PdtDesc>

    {/* admixtures */}

    <PdtDesc
      image={data.adMixtures.childImageSharp.fluid}
      heading={"Admixtures"}
      data={"Our company is tremendously involved in offering an inclusive range of Threesons Products to the clients. The Threesons retard offered by us is processed at our working place by utilizing best grade ingredients & cutting edge technology. Our Threesons retard is accessible in a gradation of packaging options & examined by quality controllers before each delivery. Our customers can take this Threesons retard from us at market leading rates."}
    ></PdtDesc>

    {/* ISOCRYSTALEX */}

    <PdtDesc
      image={data.isoCrystalex.childImageSharp.fluid}
      heading={"Isocrystalex"}
      data={"Cement based, penetrating water proofing coating system for negetive or positive side water proofing of RCC structures of M20 and above grade. Application: After surface preparations, take ISOCRYSTALEX and water @ 3:1 and apply @ 0.500 kg/sq. mt. in two coats. All surfaces should be wet/damp prior to ISOCRYSTALEX or pre-wet prior to application and cure the surface immediately after drying."}
    ></PdtDesc>

    {/* THERMOSEAL */}

    <PdtDesc
      image={data.thermoSeal.childImageSharp.fluid}
      heading={"Thermoseal"}
      data={"Heat insulation cum water proofing coating for exposed cementitious and metal surfaces. L357/kg. Application : Apply the first coat of THERMOSEAL @ 1:0.5( one part of THERMOSEAL and half parts of water) and second coat without water @ 500 gms per coat. 60-70 gms required for two coats."}
    ></PdtDesc>

    {/* ISOPHOBE */}

    <PdtDesc
      image={data.isoPhobe.childImageSharp.fluid}
      heading={"Isophobe"}
      data={"Nano Molecular water soluble chemical for converting Cement and Silica contained surface into Hydrophobic. Application : Take ISOPHOBE and mix with water. Flooding method will be most ideal for horizontal surface and spray or brush for vertical surfaces."}
      extLink={true}
      linkPath={'/products/isoPhobe'}
    ></PdtDesc>


    {/* ISOPLAST */}

    <PdtDesc
      image={data.isoPlast.childImageSharp.fluid}
      heading={"Isoplast"}
      data={"Integral waterproofing for cement plastering and concrete (RCC & PCC). Dosage is 100 ml / 50 kg of cement."}
    ></PdtDesc>

    {/* ISOFASTPLUG */}

    <PdtDesc
      image={data.isoFastPlug.childImageSharp.fluid}
      heading={"Isoflastplug"}
      data={"Fast setting cement based powder for plugging water."}
    ></PdtDesc>

    {/* IsoCoat */}
    <PdtDesc
      image={data.isocoat.childImageSharp.fluid}
      heading={'IsoCoat'}
      data={'TS Isocoat is based on acrylic modified polymers, fillers and pigments. It is available in reflective white and grey colours. It is a flexible, elastometric high-build liquid seamless roof coating. TS Isocoat is waterproof and allows concrete surfaces to breath water vapour naturally.'}
      extLink={true}
      linkPath={'/products/isoCoat'}></PdtDesc>

    {/* ISOCRETE */}
    <PdtDesc
      image={data.isoCrete.childImageSharp.fluid}
      heading={'Isocrete'}
      data={'ISOCRETE is a non-redispersable liquid bonding admixture used to produce polymer modified concrete and mortar. ISOCRETE is a milky white, water based emulsion of high solids acrylic polymers and modifiers. ISOCRETE is non-yellowing, and has excellent resistance to ultraviolet degradation, heat and most common chemicals. ISOCRETE does not alter the color of the mixture.'}
    ></PdtDesc>

  </Layout>

)

export default constructionCare;

export const query = graphql`
query {

    isocoat:file(relativePath:{eq:"isocoat.jpg"}){
      childImageSharp {
       fluid(maxWidth: 300) {
         ...GatsbyImageSharpFluid
       }
     }
   }

   isoFastPlug:file(relativePath:{eq:"isoFastPlug.jpg"}){
    childImageSharp {
     fluid(maxWidth: 300) {
       ...GatsbyImageSharpFluid
     }
   }
 }

   isoCrete:file(relativePath:{eq:"isoCrete.jpg"}){
    childImageSharp {
     fluid(maxWidth: 300) {
       ...GatsbyImageSharpFluid
     }
   }
 }
 metallicEmulsion:file(relativePath:{eq:"metallicEmulsion.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

adMixtures:file(relativePath:{eq:"adMixtures.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

isoCrystalex:file(relativePath:{eq:"isoCrystalex.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

thermoSeal:file(relativePath:{eq:"thermoSeal.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

isoPhobe:file(relativePath:{eq:"isoPhobe.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

isoPlast:file(relativePath:{eq:"isoPlast.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}
  }
`