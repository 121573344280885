import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Img from "gatsby-image"
import { Link } from '@reach/router';

const PdtDesc = (props) => (
    <>
        <Row style={{paddingBottom:"5px"}}>

            <Col xs={12} sm={8}>
            <h2>{props.heading}</h2>
			<p>{props.data}</p>
            <Link style={{display:props.extLink ?'':'none'}} to={`${props.linkPath}`}>Additional Information</Link>
            </Col>
            <Col xs={12} sm={4} md={{ span: 3, offset: 1 }}>
            <Img fluid={props.image}></Img>
            </Col>
           
        </Row>
         <hr />
         </>
)

export default PdtDesc;